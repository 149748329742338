@import '/apps/wt/webtools/sparta_build_server/tmp/2BD26A73/artifacts/components/utilities/bol-global/bol-sparta-style-utility/1.0.0/scss/_bol-sparta-style-import.scss';
@import '/apps/wt/webtools/sparta_build_server/tmp/2BD26A73/artifacts/components/utilities/global/sparta-style-utility/3.2.4/scss/_sparta-project.scss';
$moduleNameSpace: dynamic-name-space;




[data-sparta-container] .login {
  .specific_h2
  {
    margin-bottom: 0px;
    font-size: 40px !important;
  }
  .bol-card__header-styles
  {
    font-size: 16px !important;
    line-height: 1.2 !important;
    text-align: justify;

  }

  .padding-right-0
  {
    padding-right:0px;
  }

  .padding-right-10
  {
    padding-right:10px;
  }


  .img-nolink
  {
    max-width: 100%;
    height:90px;
  }

  .nolink_content
  {
    font-size: 8px;
    line-height: 1;

  }

  .padding-top-0
  {
    padding-top: 0px;
  }

  .padding-change
  {
    padding-right:90px !important;
  }

  @media screen and (max-width:1120px)
  {
    .padding-change
    {
      padding-right:10px !important;
    }
  }



  @mixin push-up($important: false) {
    @if $important {
      margin-top: $spacing-y !important;

      @media #{$medium-up} {
        margin-top: $spacing-y--md !important;
      }

      @media #{$large-up} {
        margin-top: $spacing-y--lg !important;
      }
    }

    @else {
      margin-top: $spacing-y;

      @media #{$medium-up} {
        margin-top: $spacing-y--md;
      }

      @media #{$large-up} {
        margin-top: $spacing-y--lg;
      }
    }
  }



  #hero-row {
    background-size: cover;
    background-position: center;

    @media screen and #{$small-only} {
      background-image: none !important;
    }

    h2 {
      font-size: 48px;
      line-height: 1em;
    }
  }

  .tip-tile {
    text-align: center;
    font-size: 18px;

    .tip-icon {
      height: 40px;
      width: 40px;
    }

    h4 {
      font-weight: 300;
      margin-bottom: 0;
    }

    &__link {
      &:hover {
        text-decoration: underline $color-brand-secondary;
      }
    }
  }

  .tip-icon.img-icon{
    height:80px;
    width:80px;
  }



      #messageArea {
    .spartaMessage {
      overflow: hidden;
      border-radius: $border-radius;
    }
  }

  #id-links {
    position: relative;
    top: -5px;

    p a {
      border-left: solid 1px $color-brand-tertiary;
      padding: 0 $spacing-x;

      &:first-child {
        border: none;
      }
    }
  }


  #login-loader {
    background-color: $color--primary;
    height: 292px;

    .loading-w100 {
      width: 100%;
    }

    .login-loader-container {
      padding: 0 20px;
      opacity: 0.25;
      border: none;

      .loading-textbox {
        height: 35px;
        margin: 14px 0 10px;
      }

      .loading-checkbox {
        width: 40px;
        height: 40px;
        margin: 18px 0 10px;
      }

      .loading-button {
        height: 45px;
        margin: 18px 0 10px;
      }

      .loading-link-primary {
        height: 14px;
        margin: 18px 10% 5px;
        width: 80%;
      }

      .loading-link-secondary {
        height: 14px;
        margin: 5px 23%;
        width: 54%;
      }

      .login-loading-animation {
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-name: placeHolderShimmer;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        background: no-repeat #f4f4f7;
        background-image: linear-gradient(to right, #f4f4f7 0, #656871 20%, #f4f4f7 40%);
        background-size: 800px 100%;
        position: relative;
      }
    }

    .login-loader-card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  @keyframes placeHolderShimmer {
    0% {
      background-position: 200% 0;
    }

    100% {
      background-position: -100% 0;
    }
  }


  .inside-shadow {
    box-shadow: inset 0 16px 8px -9px rgba(0, 0, 0, .16);
  }

  .background-navy {
    background: $color-brand-secondary !important;
  }

  .background-gray {
    background-color: $color-gray-s-10;
  }

  .background-white {
    background: $color-brand-tertiary !important;
  }

  .text-white {
    color: $color-brand-tertiary !important;
  }

  .text-dark-blue {
    color: $color--primary !important;
  }

  .padding-top-50 {
    padding-top: 50px !important;
  }

  .padding-bottom-0{
    padding-bottom:0px;
  }

  .padding-right-100
    {
      padding-right:100px !important;
    }

  .margin-bottom-0
  {
    margin-bottom:0px;
  }


  .push-up {
    @include push-up($important: true);
  }


  .bol-card {
    &--semi-transparent {
      background-color: rgba(255, 255, 255, 0.7) !important;
    }
  }
}


.spa-site-bol-login {

  #showMobileAppModalSpartaUILayer {

    @mixin push-up($important: false) {
      @if $important {
        margin-top: $spacing-y !important;

        @media #{$medium-up} {
          margin-top: $spacing-y--md !important;
        }

        @media #{$large-up} {
          margin-top: $spacing-y--lg !important;
        }
      }

      @else {
        margin-top: $spacing-y;

        @media #{$medium-up} {
          margin-top: $spacing-y--md;
        }

        @media #{$large-up} {
          margin-top: $spacing-y--lg;
        }
      }
    }

    .text-large {
      font-size: $font-size--large;
      line-height: $line-height--large;
    }




    .push-up {
      @include push-up($important: true);
    }

    .h6 {
      font-size: 16px;
      line-height: 24px;
    }

    #showMobileAppModal_title {
      box-shadow: none;
      color: $color--headline;
      font-family: $font--headline;
      font-weight: $font-weight--headline;
      text-align: center;

      font-size: 24px;
      line-height: 28px;
    }
  }

  #finraModalSpartaUILayer {
    .spa-content {
      padding: 0;
    }

    .spa-content-alpha-layer {
      bottom: 0;
    }

    h1 {
      color: $color--headline;
      font-family: $font--headline;
      font-weight: $font-weight--headline;

      font-size: 36px;
      line-height: 44px;
    }

    .spa-ui-layer-close {

      top: $spacing-y--modal;

      &::before,
      &::after {
        background-color: #fff;
      }
    }

    .brokerCheckHeading {
      font-size: 22px;
      line-height: 1.25em;
      color: $color--primary;
    }

    .background-navy {
      background-color: $color--primary;
    }

    .background-gray {
      background-color: $color-gray-s-10;
    }

    .bol-list-group {
      &--square {
        .bol-list-group__item {
          display: list-item;
          padding: 5px 0;
        }

        padding: 10px;
        list-style: square inside;
      }
    }


     }
}

#restest {
  display: none;
}
